<template>
  <div>
    <h1>Niet gevonden</h1>
    <p>
      Er is iets misgegaan, laad de pagina opnieuw of probeer het later nog een
      keer.
    </p>
  </div>
</template>

<script>
export default {
  name: 'notFound',
};
</script>
